/* div,
p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #4c8bf5;
  opacity: 0.2;
  border-radius: 6px;
  transition-duration: 100ms;
  z-index: -1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  z-index: 2;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 8px;
  height: 8px;
  border-right: 2px solid rgba(100, 100, 100, 0.5);
  border-bottom: 2px solid rgba(100, 100, 100, 0.5);
}

.react-grid-item:not(.react-grid-placeholder) {
  /* background: grey; */
}

.absolute-spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  z-index: 99;
}

.apexcharts-menu {
  width: 150px !important;
}

.highcharts-credits {
  display: none !important;
}

/* .highcharts-container {
  width: 100% !important;
}

.highcharts-root {
  width: 100% !important;
} */

/* .ant-picker-time-panel {
  display: none !important;
} */

.metric-card {
  margin: 0.6rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
}
.metric-card:hover {
  opacity: 0.8;
}

.ant-tabs-tab {
  margin: 0px !important;
  padding-left: 12px !important;
  border: none !important;
}
.ant-tabs-tab-active {
  background-color: #eff2f7 !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ant-tabs-tab {
  border: 0px !important;
}
.ant-tabs-tab-btn {
  color: #555 !important;
}

.ant-tabs-tabpane {
  padding: 0px !important;
}
.ant-collapse-header {
  align-items: center !important;
}
.text-widget-editor {
  height: 350px !important;
  overflow-y: scroll;
}
.text-widget-editor .DraftEditor-editorContainer {
  border: none !important;
  height: 100% !important;
  max-height: 100% !important;
}
.rdw-option-active {
  background-color: #cecece !important;
  box-shadow: none;
}
.rdw-image-modal {
  right: 0px !important;
  left: inherit;
}
.rdw-emoji-modal {
  right: 0px !important;
  left: inherit;
}
.rdw-editor-main {
  border: none !important;
}
.text-widget-editor-overflow-hidden .DraftEditor-editorContainer {
  overflow: auto !important;
  border: none !important;
  height: fit-content !important;
  max-height: none !important;
  min-height: none !important;
}
.text-widget-editor-overflow-hidden.rdw-editor-main {
  height: fit-content !important;
}
.text-widget-editor.rdw-editor-main {
  overflow: hidden;
}
.rdw-link-decorator-icon {
  display: none !important;
  font-family: monospace;
}
.sketch-picker {
  position: relative !important;
  width: 100% !important;
  box-shadow: none !important;
}

.table-chart-table .ant-table-thead > tr > th {
  border-bottom: 1px solid #bdbcbc !important;
  padding: 0 !important;
}

.table-chart-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #ebebeb !important;
  padding: 0.5rem 1rem !important;
  height: fit-content !important;
}
