.form-and-preview {
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.left-form {
  width: calc(50% - 3rem);
  height: 80%;
  position: fixed;
  left: 0;
  top: 3.5rem;
  padding-bottom: 5rem;
  z-index: 99;
  margin-left: 1.5rem;
  margin-top: 1rem;
  overflow-y: auto !important;
}

.left-form::-webkit-scrollbar {
  display: none;
}

.right-preview {
  width: 50%;
  height: 100%;
  position: fixed;
  background-color: #fafafa;
  right: 0;
  overflow-y: auto !important;
}

.right-preview::-webkit-scrollbar {
  display: none;
}

.custom-modal-body {
  margin: 0px !important;
  padding: 0px !important;
}
.custom-modal-footer {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 1;
}
.upload-btn {
  padding: 0.6rem;
  border: 1px solid #eff2f7;
  border-radius: 5px;
  font-size: 12px;
  transition: all 0.3s ease;
}
.upload-btn:hover {
  background-color: rgb(21, 7, 38, 0.04);
}

.h-line {
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin-left: 0.4rem;
}
.ant-collapse-header {
  display: flex !important;
  align-items: center;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.ant-collapse-arrow {
  display: block !important;
}
.ant-collapse-content-box {
  padding-bottom: 0px !important;
}

.post-preview {
  border-left: 1px solid rgb(21, 7, 38, 0.04);
  background-color: #edf2f7 !important;
  overflow: scroll !important;
  top: 3.9rem;
  padding-bottom: 10rem;
}

.post-preview-div {
  width: 100%;
  /* height: 80%; */
}

.post-preview-container {
  width: 60%;
  /* height: 100%; */
  /* height: 85%; */
  /* background-color: rgb(235, 235, 235); */
  border-radius: 6px;
  overflow: auto !important;
  transition: all 0.3s ease;
}

.post-template {
  background-color: white;
  border: 0px solid #d3d3d3;
  border-radius: 7px;
  overflow: auto !important;
  width: 100%;
}
.post-preview-container-desktop {
  width: 90%;
  transition: all 0.3s ease;
}
.linkedin {
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  overflow: scroll !important;
  width: 100%;
}
.linked-in-template {
  height: 100vh;
  width: 100%;
  background-color: white;
}
.linkedin-img img {
  width: 100%;
  height: auto;
}
.linkedin-dp {
  width: 40px !important;
  height: 40px !important;
}
.single-event {
  color: #333;
  border: 1px solid;
  border-color: #d3d3d3 !important;
  border-radius: 8px;
  background-color: #fafafa;
  transition: box-shadow 0.3s;
}
.single-event:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.event-box {
  /* color: #333; */
  /* position: relative; */
  width: 100% !important;
  border: 1px solid;
  border-left: none;
  border-top: none;
  border-right: none;
  border-color: #d3d3d3 !important;
  background-color: white !important;
  padding: 5px;
  /* border-radius: 8px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px !important;
}
.fc-daygrid-event {
  /* display: block !important;
  background-color: white !important;
  color: #333 !important; */
}
.fc-h-event {
  background-color: transparent !important;
  border-color: none !important;
  border-radius: 8px !important;
  border: none !important;
  color: #333 !important;
}

.cal-event-dp {
  padding: 0 !important;
  height: 26px !important;
  width: 26px !important;
}
.twitter-tweets {
  font-size: 12px;
}
.twitter-footer,
.twitter-tweets {
  border-top: 1px solid rgb(237, 237, 237);
  width: 90%;
  margin: 0 auto;
}
.twitter-footer > div svg {
  width: 20px;
  fill: #5b7083;
  transition: 0.3s ease;
}
.twitter-footer > div {
  margin: 0.2rem 0rem;
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 3px;
}
.twitter-footer > div svg:hover {
  fill: #e2264e;
}

.linkedin-footer > div {
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 3px;
}
.linkedin-footer > div:hover {
  background-color: rgb(237, 237, 237);
}
.social-accounts {
  width: 100%;
  background-color: white;
  padding: 1rem 0rem;
  margin-bottom: 1rem;
  border-radius: 6px;
}
.social-accounts > div {
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
}
.social-accounts > div:hover {
  background-color: #ededed;
}
.social-account-bg {
  background-color: #ededed;
}
.mobile-or-pc {
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #fafafa;
  border-radius: 8px;
  box-shadow: 0 0 11px #d3d3d3;
  padding: 0.5rem;
}
.mobile-or-pc > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  /* border: 1px solid #d3d3d3; */
  border-radius: 8px;
  transition: all 0.3s ease;
}
.blue-bg {
  background-color: rgb(217, 234, 245);
}
.mobile-or-pc > div:hover {
  background-color: rgb(217, 234, 245);
}

.ant-select-selection-item {
  height: fit-content !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.linkedin-option {
  cursor: pointer;
  padding: 0.2rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: all 0.3s ease;
}
.linkedin-option:hover {
  background-color: #d3d3d3;
}

.fc-event-main {
  z-index: 9999;
}
.post-content-editor {
  /* border: 1px solid #d9d9d9 !important; */
  border-radius: 8px;
}
.post-content-editor-footer {
  background-color: whitesmoke !important;
  /* border-top: 1px solid #d9d9d9; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0.5rem;
}
.DraftEditor-editorContainer {
  transition: all 0.3s ease-in-out;
  background-color: white;
  max-height: 80px !important;
  overflow: auto !important;
  margin: 0rem 0rem;
  padding: 0.5rem 0.5rem !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
}
.DraftEditor-editorContainer.expand {
  min-height: 200px !important;
}
.public-DraftEditorPlaceholder-root {
  padding: 0.5rem 0.5rem !important;
}
.public-DraftStyleDefault-block {
  margin: 0px !important;
}
.linkedin-desc > p {
  margin-bottom: 0px !important;
  white-space: pre-line !important;
}
.linkedin-desc > p:empty:before {
  content: " ";
  white-space: pre;
}
.linkedin-desc > p a {
  color: #1d9bf0 !important;
}
.DraftEditor-editorContainer a {
  color: #1d9bf0 !important;
}
.e8k2yoa,
.e13wqaj6 {
  width: 30px !important;
  height: 30px !important;
  padding: 0px !important;
  border-radius: 6px !important;
}
.upload-btn {
  border: 1px solid rgb(230, 230, 230);
  transition: all 0.3s ease-in-out;
}
.upload-btn:hover {
  background-color: #edf2f7;
}
.ant-dropdown-menu {
  border-radius: 6px !important;
}
.t16lpgj {
  border: none;
  background: none;
  box-shadow: none;
}
.e8k2yoa {
  background-color: none;
  border: none;
  border-radius: 2px;
}
.bc4rxid {
  padding: 0;
}
/* .fc-daygrid-day {
  height: 200px !important;
  overflow: scroll !important;
  background-color: red !important;
} */
.fc .fc-daygrid-day-frame {
  height: 200px !important;
  overflow: auto;
  overflow-x: hidden;
}
.channel {
  transition: all 0.3s ease;
}
.channel:hover {
  background-color: #edf2f7;
}
.bg-channel {
  background-color: #edf2f7;
}
.post-content-editor-footer .ejr02pv {
  top: 72px !important;
  bottom: -21em !important;
}
