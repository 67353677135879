.inputTitleHover,
.textareadHover {
  border-color: transparent;
  background-color: transparent;
  border-radius: 6px;
}
.inputTitleHover:hover,
.textareadHover:hover {
  border-color: rgb(21, 7, 38, 0.2);
}
.statusHover,
.dueDateHover,
.assigneeHover {
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;
}
.statusHover:hover,
.dueDateHover:hover,
.assigneeHover:hover {
  background-color: rgb(21, 7, 38, 0.04);
}

.customBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 6px;
  color: grey;
  font-weight: 500;
}

.customBtn {
  margin-left: -8px;
  padding: 2px 4px;
  /* border: 1px solid rgb(21, 7, 38, 0.04); */
}
.customBtn:hover {
  /* border: 1px solid rgb(21, 7, 38, 0.04); */
  background-color: rgb(21, 7, 38, 0.04);
  color: #333;
}

.customMargin {
  margin-left: -10px;
}

.custom-border {
  height: 1px;
  margin-top: 0px;
  margin-left: auto;
  width: 100%;
  background-color: rgb(21, 7, 38, 0.1);
}
.subtask-hover {
  transition: all 0.3s ease;
}
.subtask-hover:hover {
  background-color: #f0f1f3 !important;
}
.complete-icon-hover,
.mark-icon-hover {
  transition: color 0.3s ease;
}
.complete-icon-hover:hover,
.mark-icon-hover:hover {
  color: #25e8c8;
  border-color: #25e8c8;
}
.complete-icon {
  color: #25e8c8;
  border-color: #25e8c8;
}
.mark-icon-hover-fixed {
  color: #25e8c8;
  border-color: #25e8c8;
}

.mark-complete-btn {
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
  border: 1px solid #888;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.mark-complete-btn:hover {
  color: #25e8c8;
  border-color: #25e8c8;
}

.mark-complete-btn-fixed {
  color: #25e8c8;
  border-color: #25e8c8;
}

.icon-hover {
  color: rgb(21, 7, 38, 0.4);
  transition: color 0.3s ease;
}
.icon-hover:hover {
  color: #333;
}

.button-radius {
  font-family: sans-serif;
  border-radius: 4px;
}
.cancel-btn {
  color: #333;
}
.cancel-btn:hover {
  background-color: rgb(21, 7, 38, 0.04);
  color: #333;
  border-color: rgb(21, 7, 38, 0.1);
}

.new-space-btn {
  background-color: #2a3042;
  color: #6a7187;
  border-radius: 6px;
  transition: all 0.3s ease;
  border: 2px solid #2a3042;
  text-align: center;
}

.new-space-btn:hover {
  color: #333;
  background-color: #fafafa;
  /* border-color: #fafafa; */
}

.new-space-btn i {
  color: #6a7187;
  font-size: 1.5rem !important;
  font-weight: bold;
}
.custom-hover {
  transition: all 0.3s ease;
  /* padding: 2px; */
  border-radius: 6px;
}
.custom-hover:hover {
  background-color: #e8ecee;
}
.task-hover {
  transition: 0.3s ease;
  border: 1px solid #fff;
}
.task-hover:hover {
  border: 1px solid #ced4da;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.first-letter-styling::first-letter {
  text-transform: capitalize;
}

.first-letter-styling {
  font-size: 12px;
  padding: 3px;
}

.hover-over {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.hover-over .hover-text-is {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the hover-over */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.hover-over:hover .hover-text-is {
  visibility: visible;
}
.ant-select-clear {
  background-color: none !important;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select-multiple .ant-select-selection-item-remove {
  display: flex;
  justify-content: center;
  align-items: center;

  /* border: transparent !important;
  background-color: transparent !important; */
}

.ant-drawer-mask {
  background-color: transparent !important;
}
.ant-drawer-content-wrapper {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.task-title {
  cursor: move;
}
.task-ellipsis {
  cursor: pointer;
}
.ant-dropdown-menu-item {
  margin: 0.1rem 0rem !important;
  border-radius: 6px;
  padding: 6px 12px;
}
.ant-dropdown-menu-item:hover {
  background-color: #f0f1f3 !important;
}
.page-content::-webkit-scrollbar {
  display: none;
}
.table-responsive::-webkit-scrollbar {
  /* overflow-x: hidden !important; */
  height: 10px !important;
  transition: all 0.3s ease;
  visibility: hidden;
}
.table-responsive:hover.table-responsive::-webkit-scrollbar {
  visibility: visible !important;
  background-color: #fafafa;
  border: 1px solid rgb(208, 208, 208, 0.5);
  border-left: none;
  border-right: none;
  height: 10px !important;
  margin: 1rem !important;
}
.table-responsive:hover.table-responsive::-webkit-scrollbar-thumb {
  scrollbar-width: thin;

  border-radius: 10px !important;
  background-color: #c7c7c7;
}
.right-drawer::-webkit-scrollbar {
  /* visibility: hidden; */
  /* display: none; */
}

.ejr02pv {
  bottom: 80px !important;
  /* top: -341px !important; */
  border-radius: 6px !important;
}
.e1kg9q3n {
  height: 15em;
}
.m6zwb4v {
  color: #416dc7 !important;
  background-color: none;
}
.m6zwb4v:hover {
  text-decoration: underline !important;
  background-color: none;
  color: #416dc7;
}
.comment-text > p {
  margin: 4px 0px !important;
}

.animate-flicker {
  animation: flickerAnimation 4s 2;
}
@keyframes flickerAnimation {
  0% {
    background-color: #f9f8f8;
  }
  50% {
    background-color: rgb(237, 248, 255);
  }
  100% {
    background-color: #f9f8f8;
  }
}
.ant-timeline {
  height: 90% !important;
  overflow: scroll;
  padding: 1rem 0rem;
  padding-right: 1rem;
}
.ant-drawer-body {
  margin: 0px 2rem;
  padding: 0;
}
.bg-orange {
  background-color: #ff6c37;
}
