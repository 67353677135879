.accounts-search {
  border: 1px solid #d3d3d3 !important;
}
.chat-search-box {
  padding: 0px !important;
}
.account-box {
  cursor: pointer;
  height: 100px;
  width: 100px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 6px;
  border: 1px solid #d3d3d3;
}
.account-box:hover {
  /* background-color: #556ee6; */
  transform: scale(1.01);
}
